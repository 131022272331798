import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  Anchor,
  breakpoints,
  Button,
  cleanOrderId,
  DistributionCenter,
  getStoredDistributionCenter,
  getStoredMarket,
  getStoredZone,
  hasScrollbarStyles,
  hexToRGBA,
  ModalBackground,
  Select,
  toCssPrefix,
  useLocation,
  useShoppingCart,
  Zone,
} from '@ecommerce/shared'
import { Icon } from '../Icon/Icon'
import { LocationSelectorOption } from '../../graphql/global'
import { useUtmQueryParams } from '../../hooks/useUtmQueryParams'
import { useCartRefresh } from '../../hooks/useCartRefresh'
import useSession from '../../hooks/useSession'
import secrets from '../../config/secrets'
import { sendRegistrationMarketEvent } from '../../utils/events'

const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  img {
    width: 120px;
  }
  @media (${breakpoints.tabletPortrait.min}) {
    display: none;
  }
`

const Logo = (
  <LogoWrapper>
    <img
      src="https://images.ctfassets.net/16npdkkoi5mj/5DTF4Po0EexHavHJLoeXxz/6d5068dfd1ff02529cd6d2263c476568/Logococamicuenta.svg"
      alt="logo"
    />
  </LogoWrapper>
)

const message = 'Al cambiar de ubicación tu carro de compra podría sufrir modificaciones.'

const { cssPrefix, toPrefix } = toCssPrefix('LocationSelector__')

const Wrapper = styled.div<{
  hasSelection?: boolean
  isPrehome?: boolean
  showConfirmation: boolean
}>`
  .${cssPrefix} {
    &background {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &modal {
      height: auto;
      width: 320px;
      background: ${({ theme }) => theme.colors.white};
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      border-radius: ${({ theme }) => theme.borderRadius};
      padding: 16px 16px 36px 16px;
      gap: ${({ showConfirmation }) => (showConfirmation ? '24px' : '32px')};
    }
    &header {
      width: 100%;
      display: flex;
      flex-direction: column;
      &-row {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &-close {
        height: 100%;
        margin-bottom: 8px;
      }
      &-title {
        display: flex;
        align-self: flex-start;
        align-items: center;
        gap: 11px;
        height: 24px;
        &-text {
          flex-grow: 1;
          display: flex;
          align-self: center;
          font-size: 16px;
          font-weight: 325;
          line-height: 22px;
          letter-spacing: 0.1px;
        }
        &-desktop {
          display: none;
        }
      }
      &-close {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: end;
      }
    }
    &alert {
      width: 100%;
      display: flex;
      align-items: center;
      align-self: flex-start;
      gap: 9px;
      height: 52px;
      background: ${({ theme }) => theme.colors.grey20};
      border-radius: 4px;
      border-left: 4px solid ${({ theme }) => theme.colors.platinum80};
      &-icon {
        margin-left: 5px;
      }
      &-message {
        font-weight: 325;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.06px;
        color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
      }
    }
    &body {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      &-dropdown {
        padding: 0;
        width: 288px;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.15px;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .dropdown {
          margin-top: 8px;
          .Select__handle {
            height: 40px;
            span {
              color: ${({
                hasSelection,
                theme: {
                  colors: { platinum80, black },
                },
              }) => (hasSelection ? black : platinum80)};
            }
            .Select__change-text {
              padding-top: 5px;
              color: ${({ theme }) => theme.colors.platinum80};
            }
          }
          .Select__options {
            margin-top: 7px;
            max-height: 250px;
          }
          .Select__option-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 3px;
            .checked-icon {
              margin-top: -3px;
            }
          }
        }
      }
      &-map {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-grow: 1;
        &-title {
          text-align: center;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.15px;
          color: ${({ theme }) => theme.colors.red};
        }
        &-frame {
          flex-grow: 1;
          margin: 10px 0 20px 0;
        }
      }
    }
    &footer {
      &-confirm {
        width: 288px;
      }
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &modal {
        width: 426px;
        padding: 16px 16px 36px 16px;
      }
      &header {
        &-title {
          font-size: 18px;
          line-height: 22px;
          letter-spacing: -0.25px;
          width: 384px;
          align-self: center;
        }
      }
      &alert {
        align-self: center;
        width: 384px;
      }
      &body {
        &-dropdown {
          width: 384px;
        }
      }
      &footer {
        &-confirm {
          width: 192px;
        }
      }
    }
  }
  @media (${({ isPrehome }) => (isPrehome ? breakpoints.tabletPortrait.min : breakpoints.desktop.min)}) {
    .${cssPrefix} {
      &modal {
        height: auto;
        width: 426px;
      }
      &header {
        &-title {
          &-text {
            &-desktop {
              display: block;
            }
            &-mobile {
              display: none;
            }
          }
        }
      }
      &body {
        &-dropdown {
          width: 384px;
          .dropdown {
            margin-top: 0;
            .Select__options {
              ${hasScrollbarStyles};
              max-height: 250px;
            }
          }
        }
      }
      &footer {
        &-confirm {
          width: 182px;
          line-height: 25px;
          letter-spacing: -0.24px;
        }
      }
    }
  }
`

interface LocationSelectorModalProps {
  locationOptions: LocationSelectorOption[]
  onClose: () => void
  onExternal?: (option: LocationSelectorOption) => void
  hasCloseButton?: boolean
  isPrehome?: boolean
  requireConfirmation?: boolean
  redirectQueryParams?: string
}

export const LocationSelectorModal = ({
  onClose,
  locationOptions,
  onExternal,
  hasCloseButton = true,
  isPrehome,
  requireConfirmation = true,
  redirectQueryParams,
}: LocationSelectorModalProps) => {
  const { isBolivia } = useLocation()

  const {
    state: { byHash: cart },
  } = useShoppingCart()

  const {
    state: { byHashCities },
  } = useLocation()

  const cartHasProducts = useRef(Object.keys(cart).length).current

  const currentDistributionCenter = getStoredDistributionCenter()
  const currentMarket = getStoredMarket()
  const currentZone = getStoredZone()

  const [zones, setZones] = useState<Zone[]>([])
  const [selected, setSelected] = useState<LocationSelectorOption | null>(null)
  const [selectedZone, setSelectedZone] = useState<Zone | null>(currentZone ?? null)
  const [selectedDistributionCenter, setSelectedDistributionCenter] = useState<DistributionCenter | null>(null)
  const [showConfirmation, setShowConfirmation] = useState(false)

  const [selectIsExpanded, setSelectIsExpanded] = useState(false)
  const [selectZoneIsExpanded, setSelectZoneIsExpanded] = useState(false)

  const { refreshCart } = useCartRefresh()
  const { resetAppState } = useSession()
  const { appendUtmQuery } = useUtmQueryParams()

  const title = (
    <>
      <b className={toPrefix('header-title-desktop')}>Selecciona tu ubicación</b>
      <b className={toPrefix('header-title-mobile')}>Selecciona tu ubicación</b>
    </>
  )

  useEffect(() => {
    if (currentZone && currentMarket) {
      setSelected(locationOptions.find((option) => option.slug === currentMarket.slug) ?? null)
      setZones(currentMarket.zones)
    }
    return () => {
      setShowConfirmation(false)
    }
  }, [])

  useEffect(() => {
    if (selected && !selected.isExternal) {
      setZones(selected.zones)
    }
  }, [selected])

  useEffect(() => {
    if (selected && !selected.isExternal && selectedZone && currentZone) {
      const { distributionCenterId } = selectedZone
      setSelectedDistributionCenter(
        selected?.distributionCenters.filter((distributionCenter) => distributionCenter.id === distributionCenterId)[0],
      )
      return
    }
    if (selected && selected.isExternal && selectedZone && onExternal) return onExternal(selected)
  }, [selectedZone])

  useEffect(() => {
    if (
      currentDistributionCenter &&
      cartHasProducts &&
      currentDistributionCenter?.id !== selectedZone?.distributionCenterId
    ) {
      return setShowConfirmation(true)
    }
    if (
      currentDistributionCenter &&
      selectedDistributionCenter &&
      (currentDistributionCenter.id === selectedDistributionCenter?.id ||
        currentDistributionCenter?.id === currentZone?.distributionCenterId)
    ) {
      return setShowConfirmation(false)
    }
  }, [selectedDistributionCenter, selectedZone])

  const onSelectMarket = (option: LocationSelectorOption) => {
    if (isBolivia()) {
      setSelected(option)
      setZones(option.zones)
      setSelectedZone(option.zones[0])
      setSelectedDistributionCenter(option?.distributionCenters[0])
      return
    }
    if (option.isExternal && option.sublocations) {
      setSelected(option)
      setZones(
        option?.sublocations?.map((sublocation) => {
          return {
            name: sublocation,
            googleEmbedId: undefined,
            id: 0,
            distributionCenterId: 0,
            dailyConfigurations: undefined,
            polygon: undefined,
            internalName: '',
          }
        }),
      )
      setSelectedZone(null)
      return
    }
    setSelectedZone(null)
    setSelected(option)
    setShowConfirmation(false)
  }

  const onSelectZone = (zone: Zone) => {
    setSelectedZone(zone)
  }

  const selectPropsMarket = {
    options: locationOptions.map((option) => ({ label: `Región ${option.title}`, value: option })),
    placeholder: 'Seleccionar',
    isExpanded: selectIsExpanded,
    isShownOptions: selectIsExpanded,
    value: `Región ${selected?.title}`,
    onSelect: onSelectMarket,
    className: 'dropdown',
    onOptionsToggle: (value: boolean) => {
      setSelectZoneIsExpanded(false)
      setSelectIsExpanded(value)
    },
  }

  const selectPropsMarketBO = {
    options: locationOptions.map((option) => ({ label: option.title, value: option })),
    placeholder: 'Seleccionar',
    isExpanded: selectIsExpanded,
    onSelect: onSelectMarket,
    className: 'dropdown',
    value: selected?.title,
    onOptionsToggle: setSelectIsExpanded,
  }

  const selectPropsZone = {
    options: zones
      .filter((zone) => !secrets.EXCLUDE_ZONES.includes(zone.name))
      .map((zone) => ({ label: zone?.name ?? '', value: zone })),
    placeholder: 'Seleccionar',
    isExpanded: selectZoneIsExpanded,
    value: selectedZone?.name ?? '',
    isShownOptions: selectZoneIsExpanded,
    onSelect: onSelectZone,
    className: 'dropdown',
    onOptionsToggle: (value: boolean) => {
      setSelectIsExpanded(false)
      setSelectZoneIsExpanded(value)
    },
  }

  const marketChange = async (option: LocationSelectorOption, zone: Zone, distributionCenter: DistributionCenter) => {
    sendRegistrationMarketEvent(option.title)
    if (currentMarket && currentZone && option.slug === currentMarket.slug && zone.id === currentZone.id)
      return onClose()
    if (!option.id) return
    const redirectSlug = redirectQueryParams
      ? appendUtmQuery(`${window.location}?${redirectQueryParams ?? ''}`)
      : appendUtmQuery(`${window.location}`)
    const selectedMarket = byHashCities[option.id]

    await resetAppState(redirectSlug, {
      keepCart: true,
      keepAuth: true,
      newMarket: selectedMarket,
      newZone: zone,
      newDistributionCenter: distributionCenter,
      onFinish: () => {
        setShowConfirmation(false)
        onClose()
      },
    })

    await refreshCart(selectedMarket)

    cleanOrderId()
  }

  const onConfirm = (option: LocationSelectorOption, zone: Zone, distributionCenter: DistributionCenter) => {
    if (requireConfirmation) {
      if (cartHasProducts) {
        if (currentMarket && currentZone?.id !== option.id) {
          setSelected(option)
          setSelectedZone(zone)
          setSelectedDistributionCenter(distributionCenter)
          marketChange(option, zone, distributionCenter)
        } else onClose()
      } else marketChange(option, zone, distributionCenter)
    } else {
      marketChange(option, zone, distributionCenter)
    }
  }

  const confirm = () => {
    if (selected && selectedZone && currentZone?.id === selectedZone?.id) {
      return onConfirm(
        selected,
        selectedZone,
        selected.distributionCenters.filter(
          (distributionCenter) => distributionCenter.id === selectedZone.distributionCenterId,
        )[0],
      )
    }
    if (onConfirm && selected && selectedZone && selectedDistributionCenter)
      return onConfirm(selected, selectedZone, selectedDistributionCenter)
  }

  return (
    <>
      <Wrapper
        {...{
          hasSelection: !!selected,
          isPrehome,
          showConfirmation,
        }}
      >
        <ModalBackground className={toPrefix('background')}>
          <div className={toPrefix('modal')}>
            <div className={toPrefix('header')}>
              {isPrehome && Logo}
              <div className={toPrefix('header-row')}>
                <div className={toPrefix('header-close')}>
                  {hasCloseButton && (
                    <Anchor onClick={() => (onClose ? onClose() : null)}>
                      <Icon iconId="close" size="25" />
                    </Anchor>
                  )}
                </div>
                <div className={toPrefix('header-title')}>
                  <Icon iconId="location" size="24" fillColor="green" />
                  <p className={toPrefix('header-title-text')}>{title}</p>
                </div>
              </div>
            </div>
            {showConfirmation && (
              <div className={toPrefix('alert')}>
                <Icon className={toPrefix('alert-icon')} iconId="info_outline" size="26" fillColor="platinum80" />
                <p className={toPrefix('alert-message')}>{message}</p>
              </div>
            )}
            <div className={toPrefix('body')}>
              <div className={toPrefix('body-dropdown')}>
                {isBolivia() && (
                  <div>
                    <span className={toPrefix('body-dropdown-title')}>Ciudad</span>
                    <Select {...selectPropsMarketBO} dataTest="city-selector" />
                  </div>
                )}
                {!isBolivia() && (
                  <>
                    <div>
                      <span className={toPrefix('body-dropdown-title')}>Región</span>
                      <Select {...selectPropsMarket} dataTest="city-selector" />
                    </div>
                    <div>
                      <span className={toPrefix('body-dropdown-title')}>Comuna</span>
                      <Select {...selectPropsZone} dataTest="city-selector" />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={toPrefix('footer')}>
              <Button data-testid="button-confirm-city" className={toPrefix('footer-confirm')} onClick={confirm}>
                Guardar
              </Button>
            </div>
          </div>
        </ModalBackground>
      </Wrapper>
    </>
  )
}
