import React from 'react'
import { LocationSelectorModal } from './LocationSelectorModal'
import { LocationSelectorOption, useLocationSelectorOptions } from '../../graphql/global/locationOptions'

interface LocationSelectorWidgetProps {
  onClose: () => void
  requireConfirmation?: boolean
  redirectQueryParams?: string
  hasCloseButton?: boolean
  excludeExternal?: boolean
  onSelect?: (option: LocationSelectorOption) => void
  isPrehome?: boolean
}

export const LocationSelectorWidget = ({
  onClose,
  requireConfirmation,
  redirectQueryParams,
  hasCloseButton,
  excludeExternal = false,
  isPrehome,
}: LocationSelectorWidgetProps) => {
  const locationOptions = useLocationSelectorOptions().filter((location) => !(excludeExternal && location.isExternal))

  const onExternal = (option: LocationSelectorOption) => {
    window.location.href = option.slug
  }

  const locationSelectorProps = {
    locationOptions,
    onClose,
    onExternal,
    hasCloseButton,
    isPrehome,
    requireConfirmation,
    redirectQueryParams,
  }

  return <LocationSelectorModal {...locationSelectorProps} />
}
