import React, { useRef } from 'react'
import styled from 'styled-components'
import {
  ModalBackground,
  toCssPrefix,
  breakpoints,
  Anchor,
  Button,
  Zone,
  DistributionCenter,
  cityChangeMessage,
} from '@ecommerce/shared'
import { use100vh } from 'react-div-100vh'
import { Icon } from '../Icon/Icon'
import { LocationSelectorOption } from '../../graphql/global'
import secrets from '../../config/secrets'
import ConfirmationAlert from '../ConfirmationAlert'

const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  img {
    width: 120px;
  }
  @media (${breakpoints.tabletPortrait.min}) {
    display: none;
  }
`

const Logo = (
  <LogoWrapper>
    <img
      src="https://images.ctfassets.net/16npdkkoi5mj/5DTF4Po0EexHavHJLoeXxz/6d5068dfd1ff02529cd6d2263c476568/Logococamicuenta.svg"
      alt="logo"
    />
  </LogoWrapper>
)

const GMAPS_URL = secrets.GATSBY_GOOGLE_MAPS_URL_EMBED

const { cssPrefix, toPrefix } = toCssPrefix('LocationCoverage__')

const Wrapper = styled.div<{ viewportHeightCss: string; hasSelection?: boolean; isPrehome?: boolean }>`
  .${cssPrefix} {
    &background {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &modal {
      height: ${({ viewportHeightCss }) => viewportHeightCss};
      width: 100vw;
      background: ${({ theme }) => theme.colors.white};
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      border-radius: ${({ theme }) => theme.borderRadius};
    }

    &header {
      width: 100%;
      display: flex;
      flex-direction: column;
      box-shadow: ${({ theme }) => theme.boxShadow.lvlTwo};

      &-row {
        min-height: 72px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-title,
      &-close {
        height: 100%;
      }

      &-title {
        flex-grow: 1;
        padding-left: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.5px;

        &-desktop {
          display: none;
        }
      }

      &-close {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75px;
      }
    }

    &body {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      &-map {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-grow: 1;

        &-title {
          text-align: center;
          font-size: 17px;
          line-height: 20px;
          letter-spacing: -0.15px;
          padding: 24px 72.5px 17px 72.5px;
          color: inherit;
        }

        &-frame {
          flex-grow: 1;
          margin: 10px 0 20px 0;
        }
      }
    }

    &footer {
      height: 70px;

      &-confirm {
        width: 290px;
      }
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &body {
        &-dropdown {
          width: 367px;
        }
      }
    }
  }
  @media (${({ isPrehome }) => (isPrehome ? breakpoints.tabletPortrait.min : breakpoints.desktop.min)}) {
    .${cssPrefix} {
      &modal {
        min-height: 500px;
        height: calc(${({ isPrehome }) => (isPrehome ? '70vh' : '100vh')} - 120px);
        width: 590px;
        margin-bottom: 25px;
      }
      &header {
        &-title {
          &-desktop {
            display: block;
          }
          &-mobile {
            display: none;
          }
        }
      }
      &footer {
        &-confirm {
          width: 182px;
        }
      }
    }
  }
`

interface LocationCoverageModalProps {
  onClose?: () => void
  onConfirm?: (option: LocationSelectorOption, zone: Zone, distributionCenter: DistributionCenter) => void
  hasCloseButton?: boolean
  isPrehome?: boolean
  selectedMarket?: LocationSelectorOption
  selectedZone: Zone
  selectedDistributionCenter: DistributionCenter
  setHasOpenConfirmation: (value: boolean) => void
  hasOpenConfirmation: boolean
  requireConfirmation?: boolean
  cartHasProducts: number
  marketChange: (market: LocationSelectorOption, zone: Zone, distributionCenter: DistributionCenter) => void
}

export const LocationCoverageModal = ({
  onClose,
  onConfirm,
  hasCloseButton = true,
  isPrehome,
  selectedMarket,
  selectedZone,
  selectedDistributionCenter,
  setHasOpenConfirmation,
  hasOpenConfirmation,
  requireConfirmation,
  cartHasProducts,
  marketChange,
}: LocationCoverageModalProps) => {
  const viewportHeight = use100vh()

  const getConfirmationText = () => {
    if (!requireConfirmation) return ''
    if (cartHasProducts) return cityChangeMessage
  }

  const title = (
    <>
      <b className={toPrefix('header-title-desktop')}>Zona de cobertura</b>
      <b className={toPrefix('header-title-mobile')}>Zona de cobertura</b>
    </>
  )

  const confirmIsDisabled = !selectedMarket || selectedMarket.isExternal
  const confirm = () => {
    if (confirmIsDisabled) return
    if (onConfirm && selectedMarket && selectedZone && selectedDistributionCenter) {
      return onConfirm(selectedMarket, selectedZone, selectedDistributionCenter)
    }
  }

  return (
    <>
      <Wrapper
        {...{
          viewportHeightCss: viewportHeight ? `${viewportHeight}px` : `${viewportHeight}px`,
          hasSelection: !!selectedMarket,
          isPrehome,
        }}
      >
        <ModalBackground className={toPrefix('background')}>
          <div className={toPrefix('modal')}>
            <div className={toPrefix('header')}>
              {isPrehome && Logo}
              <div className={toPrefix('header-row')}>
                <div className={toPrefix('header-title')}>{title}</div>
                <div className={toPrefix('header-close')}>
                  {hasCloseButton && (
                    <Anchor onClick={() => (onClose ? onClose() : null)}>
                      <Icon iconId="close" size="25" />
                    </Anchor>
                  )}
                </div>
              </div>
            </div>
            <div className={toPrefix('body')}>
              <div className={toPrefix('body-map')}>
                {selectedZone && selectedZone.googleEmbedId && (
                  <>
                    <span className={toPrefix('body-map-title')}>Confirma la zona de cobertura de comuna</span>
                    <iframe
                      className={toPrefix('body-map-frame')}
                      title="location-selector-map"
                      src={`${GMAPS_URL}${selectedZone.googleEmbedId}`}
                      frameBorder="0"
                    />
                  </>
                )}
              </div>
            </div>
            <div className={toPrefix('footer')}>
              <Button
                isDisabled={confirmIsDisabled}
                className={toPrefix('footer-confirm')}
                onClick={() => {
                  if (cartHasProducts > 0) {
                    return setHasOpenConfirmation(true)
                  }
                  confirm()
                  return onClose ? onClose() : null
                }}
              >
                Cambiar de ciudad
              </Button>
            </div>
          </div>
        </ModalBackground>
      </Wrapper>
      {hasOpenConfirmation && (
        <ConfirmationAlert
          onClose={() => setHasOpenConfirmation(false)}
          onConfirm={() => {
            return selectedMarket && selectedZone && selectedDistributionCenter
              ? marketChange(selectedMarket, selectedZone, selectedDistributionCenter)
              : null
          }}
          onCancel={() => {
            setHasOpenConfirmation(false)
            return onClose ? onClose() : null
          }}
          confirmButtonText="Continuar"
          cancelButtonText="Cancelar"
          text={getConfirmationText()}
        />
      )}
    </>
  )
}
